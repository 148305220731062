<template>
  <div class="mb-4 list-options mb-sm-2">
    <v-card outlined>
      <v-card-title class="toolbar-title">
        {{ $t('leads.page-title') }}
      </v-card-title>
      <v-card-text
        class="flex-wrap w-full d-flex pa-0"
        :class="{
          'justify-space-between':($vuetify.breakpoint.mdAndUp || $vuetify.breakpoint.xs),
          'justify-center':$vuetify.breakpoint.sm,
        }"
      >
        <div
          class="d-flex"
          :class="{'w-full justify-space-between flex-column':$vuetify.breakpoint.xs} "
        >
          <!-- Filter -->
          <div
            v-if="!isPlatformMarkero"
            class="d-flex align-center px-2 option-container"
            :class="{'w-full':$vuetify.breakpoint.xs}"
          >
            <v-badge
              :value="activeFiltersCount > 0"
              :content="activeFiltersCount"
              color="red"
              overlap
              :class="{'w-full':$vuetify.breakpoint.xs}"

              right
            >
              <v-btn
                outlined
                color="primary"
                class="camel-case option-btn"
                @click="$emit('toggleLeadListTool', 'filter')"
              >
                <v-icon left>
                  mdi-filter-variant
                </v-icon>
                {{ $t('labels.filter') }}
              </v-btn>
            </v-badge>
          </div>
          <!-- Search -->
          <div
            class="pa-2 flex-grow-1 lead-list__search"
            outlined
          >
            <v-text-field
              v-model="listOptions.search"
              class="mt-2"
              :label="$t('labels.leads.lead-search')"
              hide-details
              single-line
              clearable
              solo
              flat
              dense
              outlined
              append-icon="mdi-magnify"
            />
          </div>
        </div>

        <!-- Expanded Actions for Desktop -->
        <div
          class="d-flex align-center"
          :class="{'w-full justify-space-between flex-column':$vuetify.breakpoint.xs} "
        >
          <!-- Create new Lead -->
          <Permission permission="lead:create">
            <CreateLeadDialog
              class="px-2"
              :refetch-parent="refetchParent"
            >
              <v-btn
                color="primary"
                class="camel-case w-full"
              >
                <v-icon
                  small
                  left
                >
                  mdi-account-plus
                </v-icon>
                {{ $t('buttons.leads.create') }}
              </v-btn>
            </CreateLeadDialog>
          </Permission>

          <!-- Import -->
          <Permission permission="lead:import">
            <Feature :feature-slug="featureNames.LEAD_IMPORT">
              <v-btn
                v-if="$vuetify.breakpoint.mdAndUp"
                color="primary"
                class="camel-case mx-2"
                @click="$emit('showImport')"
              >
                <v-icon
                  small
                  left
                >
                  mdi-upload
                </v-icon>
                {{ $t('buttons.leads.import') }}
              </v-btn>
            </Feature>
          </Permission>

          <!-- Acquisition Booster -->
          <Permission permission="lead:invite-bulk">
            <Feature :feature-slug="featureNames.ACQUISITION_BOOSTER">
              <AcquisitionBoosterButton
                :is-disabled="mailProvider !== 'HOPPERMATION'"
                icon="mdi-rocket-launch-outline"
                :label="$t('labels.acquisition-booster.name')"
                @click="$emit('toggleLeadListTool', 'invitation')"
              />
            </Feature>
          </Permission>
        </div>

        <!-- List or Grid View -->
        <div
          class="d-flex"
          :class="{
            'align-center':$vuetify.breakpoint.smAndUp,
            'px-2':$vuetify.breakpoint.smAndDown}"
        >
          <!-- List view -->
          <Permission permission="lead:write">
            <All
              :leads-view-selected="leadsViewSelected"
              @select="changeView('list')"
            />
          </Permission>

          <!-- Grid view -->
          <Permission permission="lead:write">
            <Platform
              :leads-view-selected="leadsViewSelected"
              @select="changeView('pipeline')"
            />
          </Permission>
        </div>

        <!-- Actions -->
        <div
          class="justify-end d-flex align-center actions"
          :class="{'justify-space-between flex-column':$vuetify.breakpoint.xs} "
        >
          <!-- More Functions -->
          <div
            class="px-2 option-container d-flex align-center"
            :class="{'w-full':$vuetify.breakpoint.xs}"
          >
            <v-menu
              bottom
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="camel-case vertical-dots-wrapper"
                  color="primary"
                  outlined
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    class="vertical-dots"
                    left
                  >
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <Permission permission="lead:export-bulk">
                  <v-list-item class="list-item-container">
                    <Feature :feature-slug="featureNames.LEAD_EXPORT">
                      <Export @exportLeads="(value) => $emit('exportLeads', value)" />
                    </feature>
                  </v-list-item>
                </Permission>

                <v-list-item class="list-item-container">
                  <ColumnSettingsButton
                    icon="mdi-view-column"
                    :label="$t('labels.display')"
                    @click="$emit('toggleLeadListTool', 'column')"
                  />
                </v-list-item>

                <Permission
                  v-if="!isPipelineView"
                  permission="lead:delete-bulk"
                >
                  <Feature :feature-slug="featureNames.DELETE_LEADS">
                    <v-list-item class="list-item-container">
                      <ColumnSettingsButton
                        icon="mdi-delete"
                        :label="$t('labels.delete')"
                        @click="$emit('toggleLeadListTool', 'deleteLeads')"
                      />
                    </v-list-item>
                  </Feature>
                </Permission>

                <Permission
                  v-if="!isPipelineView"
                  permission="lead:assign-bulk"
                >
                  <v-list-item class="list-item-container">
                    <ColumnSettingsButton
                      icon="mdi-account-arrow-right-outline"
                      :label="$t('labels.assign')"
                      @click="$emit('toggleLeadListTool', 'assignLeads')"
                    />
                  </v-list-item>
                </Permission>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import currencyMixin from '@/mixins/currency'
import featureMixin from '@/mixins/feature'
import MAIL_PROVIDER from '../queries/MailProvider.gql'
import brandingMixin from '@/mixins/branding'
import AcquisitionBoosterButton from './AcquisitionBoosterButton.vue'
import CreateLeadDialog from './CreateLeadDialog.vue'
import ColumnSettingsButton from './ColumnSettingsButton.vue'
import Permission from '@/components/Permission'
import All from '@/modules/leads/All.vue'
import Platform from '@/modules/leads/Platform.vue'

export default {
  components: {
    Platform,
    All,
    Export: () => import('../Export'),
    AcquisitionBoosterButton,
    CreateLeadDialog,
    ColumnSettingsButton,
    Permission
  },
  mixins: [currencyMixin, brandingMixin, featureMixin],
  props: {
    leadsViewSelected: {
      type: String,
      required: false
    },
    listOptions: {
      type: Object,
      required: true
    },
    activeFiltersCount: {
      type: Number,
      required: true
    },
    refetchParent: {
      type: Function,
      required: true
    }
  },
  computed: {
    isPipelineView () {
      return this.leadsViewSelected === 'pipeline'
    }
  },
  methods: {
    onClick (label, value) {
      this.$tracking.event('Leads', this.$tracking.trackingEvents.CLICKED, label, value)
    },
    changeView (view) {
      sessionStorage.setItem('leadsViewSelected', JSON.stringify(view))
      this.$emit('changeView', view)
    }
  },
  apollo: {
    mailProvider: {
      query: MAIL_PROVIDER,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      },
      update: (data) => data.company.mailProvider
    }
  }
}
</script>

<style>
.toolbar-title {
  color: #414142;
  padding: 5px 10px 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.option-container{
  height: 50px;
}

.option-btn  {
  width: 100%;
}

.option-btn .v-btn__content {
  padding-left: 10px;
  padding-right: 10px;
}

/* breakpoint md and up */
@media (min-width: 600px) {
  .lead-list__search{
    min-width: 350px;
    max-width: 350px;
  }

}

.camel-case {
    text-transform: unset !important;
    font-weight: normal;
}

.vertical-dots-wrapper {
  min-width: unset !important;
}

.vertical-dots {
  margin: 0 !important;
}

.list-options .v-input--selection-controls .v-input__slot, .v-input--select{
  margin-bottom: 0;
}

.list-options .v-text-field.v-text-field--enclosed{
  margin-top:0px !important;
}

.activate-window-step{
  max-width: 600px
}

.list-item-container{
  padding: 0px;
}

.list-item-container:hover{
  background-color: rgb(245, 245, 245);
}

.menu-list-item{
  padding-left: 15px;
  padding-right: 15px;
  height: 48px;
  display:flex;
  align-items:center;
  cursor: pointer;
}

.filter-container{
  position:relative;
}

@media (min-width: 644px) and (max-width: 757px), (min-width: 1325px) {
  .actions .option-container {
    height: 50px;
  }

}

</style>
